<template>
  <section>
    <div>
      <div class="concea">
        <span>Source From Deribit</span>
      </div>
      <div class="optionsShow">
        <iframe
          src="https://metrics.deribit.com/options?index=BTC"
          frameborder="0"
          width="100%"
          height="1600px"
          scrolling="no"
        ></iframe>
      </div>
      <!-- <div>
        <el-tabs :stretch="false" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="BTC" name="first">
            <span slot="label">
              <img
                src="@/assets/images/pages/BTC.png"
                alt=""
                style="width: 23px"
              />
              BTC
            </span>

            <b-row class="StrikePrice">
              <b-col class="StrikePrice_root">
                <b-card no-body>
                  <b-card-header>
                    <b-card-title>
                      <div class="MuiGrid-root">
                        <div class="MuiGrid-item">
                          24h Put Volume:
                          <div class="PutVolume">28,067.00</div>
                        </div>
                        <div class="MuiGrid-item">
                          24h Call Volume:
                          <div class="CallVolume">28,067.00</div>
                        </div>
                        <div class="MuiGrid-item">
                          Put/Call Ratio:
                          <div class="Ratio">28,067.00</div>
                        </div>
                      </div>
                    </b-card-title>
                  </b-card-header>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="match-height">
              <b-col>
                <strike-price
                  ref="chart1"
                  :chartType="1"
                  title="Open Interest By Strike Price"
                />
              </b-col>
            </b-row>

            <b-row class="match-height">
              <b-col>
                <strike-price
                  ref="chart2"
                  :chartType="2"
                  title="Open Interest By Strike Price"
                />
              </b-col>
            </b-row>

            <b-row class="match-height">
              <b-col lg="4">
                <expiration
                  ref="chart3"
                  :chartType="3"
                  title="Open Interest By Expiration"
                />
              </b-col>
              <b-col lg="4">
                <open-interest
                  ref="chart4"
                  :chartType="4"
                  title="Open Interest (Prev. day)"
                />
              </b-col>
              <b-col lg="4">
                <historical-volatility
                  ref="chart5"
                  :chartType="5"
                  title="Historical Volatility"
                />
              </b-col>
            </b-row>

            <b-row class="match-height">
              <b-col lg="4">
                <volume
                  ref="chart6"
                  :chartType="6"
                  title="Volume (Previous day)"
                />
              </b-col>
              <b-col lg="4">
                <expiration
                  ref="chart7"
                  :chartType="7"
                  title="Open Interest (Prev. day)"
                />
              </b-col>
              <b-col lg="4">
                <expiration
                  ref="chart8"
                  :chartType="8"
                  title="Open Interest By Strike Price"
                />
              </b-col>
            </b-row>
          </el-tab-pane>

          <el-tab-pane label="ETH" name="second">
            <span slot="label">
              <img
                src="@/assets/images/pages/ETH.png"
                alt=""
                style="width: 23px"
              />
              ETH
            </span>
          </el-tab-pane>
        </el-tabs>
      </div> -->
    </div>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import StrikePrice from "@/views/charts-and-maps/charts/echart/option-echart/StrikePrice.vue";
import Expiration from "@/views/charts-and-maps/charts/echart/option-echart/Expiration.vue";
import OpenInterest from "@/views/charts-and-maps/charts/echart/option-echart/OpenInterest.vue";
import HistoricalVolatility from "@/views/charts-and-maps/charts/echart/option-echart/HistoricalVolatility.vue";
import volume from "@/views/charts-and-maps/charts/echart/option-echart/Volume.vue";
export default {
  name: "options",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    StrikePrice,
    Expiration,
    OpenInterest,
    HistoricalVolatility,
    volume,
  },
  data() {
    return {
      activeName: "first",
      url: "@/assets/images/pages/BTC.png",
      url1: "@/assets/images/pages/ETH.png",

      options: [
        {
          value: "All Expirations",
          label: "All Expirations",
        },
        {
          value: "Sep 26 2021",
          label: "Sep 26 2021",
        },
        {
          value: "Sep 27 2021",
          label: "Sep 27 2021",
        },
        {
          value: "Oct 01 2021",
          label: "Oct 01 2021",
        },
        {
          value: "Oct 08 2021",
          label: "Oct 08 2021",
        },
        {
          value: "Oct 15 2021",
          label: "Oct 15 2021",
        },
        {
          value: "Oct 29 2021",
          label: "Oct 29 2021",
        },
        {
          value: "Nov 26 2021",
          label: "Nov 26 2021",
        },
        {
          value: "Dec 31 2021",
          label: "Dec 31 2021",
        },
        {
          value: "Mar 25 2022",
          label: "Mar 25 2022",
        },
        {
          value: "Jun 24 2022",
          label: "Jun 24 2022",
        },
        {
          value: "Sep 30 2022",
          label: "Sep 30 2022",
        },
      ],
      options2: [
        {
          value: "All Expirations",
          label: "All Expirations",
        },
        {
          value: "Sep 26 2021",
          label: "Sep 26 2021",
        },
        {
          value: "Sep 27 2021",
          label: "Sep 27 2021",
        },
        {
          value: "Oct 01 2021",
          label: "Oct 01 2021",
        },
        {
          value: "Oct 08 2021",
          label: "Oct 08 2021",
        },
        {
          value: "Oct 15 2021",
          label: "Oct 15 2021",
        },
        {
          value: "Oct 29 2021",
          label: "Oct 29 2021",
        },
        {
          value: "Nov 26 2021",
          label: "Nov 26 2021",
        },
        {
          value: "Dec 31 2021",
          label: "Dec 31 2021",
        },
        {
          value: "Mar 25 2022",
          label: "Mar 25 2022",
        },
        {
          value: "Jun 24 2022",
          label: "Jun 24 2022",
        },
        {
          value: "Sep 30 2022",
          label: "Sep 30 2022",
        },
      ],
      options3: [
        {
          value: "# of Contracts",
          label: "# of Contracts",
        },
        {
          value: "$ Value",
          label: "$ Value",
        },
      ],
      options4: [
        {
          value: "Week",
          label: "Week",
        },
        {
          value: "Month",
          label: "Month",
        },
        {
          value: "Year",
          label: "Year",
        },
        {
          value: "All",
          label: "All",
        },
      ],
      value: "",
      value2: "",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    public(type, val) {
      // if(tpye === 1) {
      //   let
      // }
    },
  },
};
</script>

<style lang="scss">
.concea {
  width: 100%;
  height: 40px;
  span {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }
}
.optionsShow {
  position: relative;
  z-index: 1;
}
.MuiGrid-root {
  display: flex;
  margin-bottom: 10px;
  .MuiGrid-item {
    color: rgb(173, 171, 171);
    display: flex;
    .PutVolume {
      color: #fff;
      margin: 0 10px;
    }
    .CallVolume {
      color: #fff;
      margin: 0 10px;
    }
    .Ratio {
      color: #fff;
      margin: 0 10px;
    }
  }
}
// .text {
//   font-size: 16px;
//   font-weight: 600;
// }

// .item {
//   margin-bottom: 18px;
// }

// .clearfix:before,
// .clearfix:after {
//   display: table;
//   content: "";
// }
// .clearfix:after {
//   clear: both;
// }

// .box-card {
//   width: 100%;
//   margin-bottom: 7px;
//   .header {
//     display: block;
//     font-size: 16px;
//     font-weight: 600;
//     .chart-select {
//       display: block;
//       position: absolute;
//       right: 0;
//     }
//     .line {
//       border: none;
//       height: 1px;
//       margin: 0;
//       flex-shrink: 0;
//       background-color: rgb(255 255 255 / 8%);
//     }
//   }
// }
// .Price_interest {
//   padding: 16px;
//   .interest-content {
//     display: flex !important;
//     .interest-item {
//       padding: 8px;
//       .subtitle2 {
//       }
//       .subtitle1 {
//       }
//     }
//   }
// }
</style>