<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50"> {{ title }} </b-card-title>
    </b-card-header>
    <div class="ivu-col select-container">
      <div class="chart-select">
        <el-select v-model="timeSplit" placeholder="1 Month">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <b-card-body>
      <div ref="Volume" :style="{ width: '100%', height: '300px' }"></div>
    </b-card-body>
  </b-card>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    // 动态传递一个数据
    series: {
      type: Array,
    },
    // 动态传递一个title
    title: {
      type: String,
    },
    chartType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      options: [
        {
          value: "1 Month",
          label: "1 Month",
        },
        {
          value: "3 Months",
          label: "3 Months",
        },
        {
          value: "6 Months",
          label: "6 Months",
        },
        {
          value: "1 Year",
          label: "1 Year",
        },
        {
          value: "All",
          label: "All",
        },
      ],
      timeSplit: "",
    };
  },
  mounted() {
    // console.log("echart", echarts);
    this.getEchartData();
  },
  methods: {
    getEchartData() {
      const chart = this.$refs.Volume;
      if (chart) {
        const myChart = echarts.init(chart);
        let xAxisData = [];
        let data1 = [];
        let data2 = [];
        let data3 = [];
        let data4 = [];
        for (let i = 0; i < 10; i++) {
          xAxisData.push("Class" + i);
          data1.push(+(Math.random() * 2).toFixed(2));
          data2.push(+(Math.random() * 5).toFixed(2));
          data3.push(+(Math.random() + 0.3).toFixed(2));
          data4.push(+Math.random().toFixed(2));
        }
        var emphasisStyle = {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0,0.3)",
          },
        };
        const option = {
          legend: {
            data: ["bar", "bar2", "bar3", "bar4"],
            left: "10%",
          },
          // brush: {
          //     toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
          //     xAxisIndex: 0
          // },
          //工具箱
          // toolbox: {
          //     feature: {
          //     magicType: {
          //         type: ['stack']
          //     },
          //     dataView: {}
          //     }
          // },
          tooltip: {},
          xAxis: {
            data: xAxisData,
            name: "X Axis",
            axisLine: { onZero: true },
            splitLine: { show: false },
            splitArea: { show: false },
          },
          yAxis: {},
          grid: {
            bottom: 100,
          },
          //缩放
          dataZoom: [
            {
              type: "inside",
            },
            {
              type: "slider",
            },
          ],
          series: [
            {
              name: "bar",
              type: "bar",
              stack: "one",
              emphasis: emphasisStyle,
              data: data1,
            },
            {
              name: "bar2",
              type: "bar",
              stack: "one",
              emphasis: emphasisStyle,
              data: data2,
            },
            {
              name: "bar3",
              type: "bar",
              stack: "one",
              emphasis: emphasisStyle,
              data: data3,
            },
            {
              name: "bar4",
              type: "bar",
              stack: "one",
              emphasis: emphasisStyle,
              data: data4,
            },
          ],
        };
        myChart.on("brushSelected", function (params) {
          var brushed = [];
          var brushComponent = params.batch[0];
          for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
            var rawIndices = brushComponent.selected[sIdx].dataIndex;
            brushed.push("[Series " + sIdx + "] " + rawIndices.join(", "));
          }
          myChart.setOption({
            title: {
              backgroundColor: "#333",
              text: "SELECTED DATA INDICES: \n" + brushed.join("\n"),
              bottom: 0,
              right: "10%",
              width: 100,
              textStyle: {
                fontSize: 12,
                color: "#fff",
              },
            },
          });
        });
        myChart.setOption(option);

        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>
<style lang="scss">
</style>
