<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50"> {{ title }} </b-card-title>
      <div class="ivu-col select-container">
        <div class="chart-select">
          <el-select v-model="StrikePrice" placeholder="All Expirations">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div ref="Strike_Price" :style="{ width: '100%', height: '300px' }"></div>
      <hr class="line" />
      <div class="Price_interest">
        <div class="interest-content">
          <div class="interest-item">
            <h6
              class="subtitle2"
              style="
                border-bottom: 5px solid rgb(21, 200, 177);
                padding-bottom: 4px;
                width: 232px;
              "
            >
              Call Open Interest
            </h6>
            <h6 class="subtitle1" style="margin-top: 4px; width: 232px">
              86,599.00
            </h6>
          </div>
          <div class="interest-item">
            <h6
              class="subtitle2"
              style="
                border-bottom: 2px solid rgb(25, 120, 208);
                padding-bottom: 4px;
                width: 232px;
              "
            >
              Put Open Interest
            </h6>
            <h6 class="subtitle1" style="margin-top: 4px; width: 232px">
              44,715.20
            </h6>
          </div>
          <div class="interest-item">
            <h6
              class="subtitle2"
              style="
                border-bottom: 2px solid rgb(218, 132, 30);
                padding-bottom: 4px;
                width: 232px;
              "
            >
              Put/Call Ratio
            </h6>
            <h6 class="subtitle1" style="margin-top: 4px; width: 232px">
              0.52
            </h6>
          </div>
          <div class="interest-item">
            <h6
              class="subtitle2"
              style="
                border-bottom: 2px solid rgb(167, 25, 208);
                padding-bottom: 4px;
                width: 232px;
              "
            >
              Total Open Interest
            </h6>
            <h6 class="subtitle1" style="margin-top: 4px; width: 232px">
              131,314.20
            </h6>
          </div>
          <div class="MuiGrid-item">
            <h6
              class="subtitle2"
              style="
                border-bottom: 2px solid rgb(69, 79, 89);
                padding-bottom: 4px;
                width: 232px;
              "
            >
              Notional Value
            </h6>
            <h6 class="subtitle1" style="margin-top: 4px; width: 232px">
              $5,604,563,104.64
            </h6>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    // 动态传递一个数据
    series: {
      type: Array,
    },
    // 动态传递一个title
    title: {
      type: String,
    },
    chartType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      options: [
        {
          value: "All Expirations",
          label: "All Expirations",
        },
        {
          value: "Sep 26 2021",
          label: "Sep 26 2021",
        },
        {
          value: "Sep 27 2021",
          label: "Sep 27 2021",
        },
        {
          value: "Oct 01 2021",
          label: "Oct 01 2021",
        },
        {
          value: "Oct 08 2021",
          label: "Oct 08 2021",
        },
        {
          value: "Oct 15 2021",
          label: "Oct 15 2021",
        },
        {
          value: "Oct 29 2021",
          label: "Oct 29 2021",
        },
        {
          value: "Nov 26 2021",
          label: "Nov 26 2021",
        },
        {
          value: "Dec 31 2021",
          label: "Dec 31 2021",
        },
        {
          value: "Mar 25 2022",
          label: "Mar 25 2022",
        },
        {
          value: "Jun 24 2022",
          label: "Jun 24 2022",
        },
        {
          value: "Sep 30 2022",
          label: "Sep 30 2022",
        },
      ],
      StrikePrice: "",
    };
  },
  mounted() {
    // console.log("echart", echarts);
    this.getEchartData();
    window.addEventListener("resize", this.chart.resize);
  },
  methods: {
    getEchartData() {
      const eChart = this.$refs.Strike_Price;
      this.chart = echarts.init(eChart);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["Evaporation", "Precipitation", "Temperature"],
        },
        xAxis: [
          {
            type: "category",
            data: [
              "44000",
              "46000",
              "48000",
              "49000",
              "50000",
              "51000",
              "52000",
              "53000",
              "54000",
              "55000",
              "56000",
              "57000",
              "58000",
              "59000",
              "60000",
              "61000",
              "62000",
              "63000",
              "64000",
              "65000",
            ],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: "Precipitation",
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            // name: "Temperature",
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
              formatter: "{value} M",
            },
          },
        ],
        series: [
          {
            name: "Evaporation",
            type: "bar",
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3, 2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2,
            ],
          },
          {
            name: "Precipitation",
            type: "bar",
            data: [
              2.6, 5.9, 9.0, 0, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2,
            ],
          },
          {
            name: "Temperature",
            type: "line",
            yAxisIndex: 1,
            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4,
            ],
          },
        ],
      };
      // 设置配置项
      this.chart.setOption(option);
    },
  },
};
</script>
<style lang="scss">
.select-container {
  display: flex;
  .chart-select {
    display: flex;
    flex-direction: column;
    padding-right: 5px;
    width: 150px !important;
    color: #ffff;
  }
}
.Price_interest {
  padding: 16px;
  .interest-content {
    display: flex !important;
    .interest-item {
      padding: 8px;
      .subtitle2 {
      }
      .subtitle1 {
      }
    }
  }
}
</style>
