<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50"> {{ title }} </b-card-title>
    </b-card-header>
    <b-card-body>
      <div ref="Expiration" :style="{ width: '100%', height: '300px' }"></div>
    </b-card-body>
  </b-card>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    // 动态传递一个数据
    series: {
      type: Array,
    },
    // 动态传递一个title
    title: {
      type: String,
    },
    chartType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // console.log("echart", echarts);
    this.getEchartData();
  },
  methods: {
    getEchartData() {
      const chart = this.$refs.Expiration;
      if (chart) {
        const myChart = echarts.init(chart);
        const option = {
          title: {
            // text: "Open Interest By Strike Price",
          },
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            formatter: (params) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

              params.forEach((item) => {;
                result += `<span style="
              ">${item.seriesName} :${item.value}%</span></br>`;
              });
              return result;
            },
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          legend: {
            data: ["calls", "puts"],
          },
          xAxis: {
            data: ["12000", "15000", "16000", "18000", "20000", "22000"],
          },
          yAxis: {},
          series: [
            {
              name: "calls",
              type: "bar",
              data: [1200, 700, 1600, 2500, 2000, 2200],
            },
            {
              name: "puts",
              type: "bar",
              data: [700, 1200, 600, 200, 2000, 2200],
            },
          ],
        };
        myChart.setOption(option);

        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>
<style lang="scss">
</style>
