<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50"> {{ title }} </b-card-title>
      <div class="ivu-col select-container">
        <div class="chart-select">
          <el-select v-model="timeSplit" placeholder="Month">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div
        ref="Historical_Volatility"
        :style="{ width: '100%', height: '300px' }"
      ></div>
    </b-card-body>
  </b-card>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    // 动态传递一个数据
    series: {
      type: Array,
    },
    // 动态传递一个title
    title: {
      type: String,
    },
    chartType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      options: [
        {
          value: "Day",
          label: "Day",
        },
        {
          value: "Week",
          label: "Week",
        },
        {
          value: "Month",
          label: "Month",
        },
      ],
      timeSplit: "",
    };
  },
  mounted() {
    // console.log("echart", echarts);
    this.getEchartData();
  },
  methods: {
    getEchartData() {
      const chart = this.$refs.Historical_Volatility;
      if (chart) {
        const myChart = echarts.init(chart);
        const option = {
          title: {
            // text: "Open Interest By Strike Price",
          },
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            formatter: (params) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

              params.forEach((item) => {
                var aaa = this.kmb(item.value);
                result += `<span style="color:rgba(100, 176, 143, 1)">${item.seriesName} :${aaa}%</span></br>`;
              });
              return result;
            },
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          xAxis: {
            data: [
              "12.sep",
              "14.sep",
              "16.sep",
              "18.sep",
              "20.sep",
              "22.sep",
              "24.sep",
              "26.sep",
            ],
          },
          yAxis: {},
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 20,
            },
            {
              start: 0,
              end: 20,
            },
          ],

          series: [
            {
              name: "volatility",
              type: "line",
              datasetId: "dataset_since_1950_of_france",
              showSymbol: false,
              data: [25, 30, 39, 70, 1, 18, 80, 36],
            },
          ],
        };
        myChart.setOption(option);

        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>
<style lang="scss">
.select-container {
  display: flex;
  .chart-select {
    margin-right: 5px;
    width: 100px;
    size: 14;
  }
}
</style>
