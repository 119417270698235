<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50"> {{ title }} </b-card-title>
      <div class="ivu-col select-container">
        <div class="chart-select" >
          <el-select
            v-model="Contracts"
            placeholder="# of Contracts"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="chart-select">
          <el-select
            v-model="timeSplit"
            placeholder="Year"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div ref="Open_Interest" :style="{ width: '100%', height: '300px' }"></div>
    </b-card-body>
  </b-card>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
export default {
   components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    // 动态传递一个数据
    series: {
      type: Array,
    },
    // 动态传递一个title
    title: {
      type: String,
    },
    chartType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      options: [
        {
          value: "# of Contracts",
          label: "# of Contracts",
        },
        {
          value: "$ Value",
          label: "$ Value",
        },
      ],
      options2: [
        {
          value: "Week",
          label: "Week",
        },
        {
          value: "Month",
          label: "Month",
        },
        {
          value: "Year",
          label: "Year",
        },
        {
          value: "All",
          label: "All",
        },
        
      ],
      Contracts:"",
      timeSplit:"",
    };
  },
  mounted() {
    // console.log("echart", echarts);
    this.getEchartData();
  },
  methods: {
    getEchartData() {
      const chart = this.$refs.Open_Interest;
      let base = +new Date(1988, 9, 3);
      let oneDay = 24 * 3600 * 1000;
      let data = [[base, Math.random() * 300]];
      for (let i = 1; i < 20000; i++) {
        let now = new Date((base += oneDay));
        data.push([
          +now,
          Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
        ]);
      }
      if (chart) {
        const myChart = echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "axis",
            position: function (pt) {
              return [pt[0], "10%"];
            },
          },
          title: {
            // left: "center",
            // text: "Large Ara Chart",
          },
          //工具：区域缩放，还原，保存图片
        //   toolbox: {
        //     feature: {
        //       dataZoom: {
        //         yAxisIndex: "none",
        //       },
        //       restore: {},
        //       saveAsImage: {},
        //     },
        //   },
          xAxis: {
            type: "time",
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, "100%"],
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 20,
            },
            {
              start: 0,
              end: 20,
            },
          ],
          series: [
            {
              name: "Fake Data",
              type: "line",
              smooth: true,
              symbol: "none",
              areaStyle: {},
              data: data,
            },
          ],
        };
        myChart.setOption(option);

        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>
<style lang="scss">
.select-container{
  display: flex;
  .chart-select{
    margin-right: 5px;
  width:100px;
  size: 14;
}
}

</style>
